<template>
  <div class="remnants">
    <div class="remnants__body">
      <div class="remnants__input">
        <div class="form-control">
          <input
            v-model="code"
            type="text"
            class="form-control__input"
            placeholder="Введите код номенклатуры"
          >
        </div>
      </div>
      <div class="remnants__button">
        <button class="button button--mini" type="submit" @click="checkRemnants">
          <span>Получить данные из 1С</span>
        </button>
      </div>
    </div>
    <div v-if="isJsonShow" class="remnants__json">
      <json-viewer :value="jsonData" :expand-depth="999" :copyable="true" :theme="'json-viewer jv-light'">
        <template slot="copy">
          <button class="button button--mini" type="button">
            <span>Копировать</span>
          </button>
        </template>
      </json-viewer>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'Remnants1c',
  components: {
    JsonViewer
  },
  data() {
    return {
      code: '',
      jsonData: {},
      isJsonShow: false
    }
  },
  methods: {
    checkRemnants() {
      this.isJsonShow = false
      this.$store.dispatch('c1/GET_PRODUCTS', this.code)
        .then(res => {
          this.code = ''
          if (res.data.error) {
            this.$notify({
              type: 'warn',
              title: 'Внимание!',
              text: res.data.error
            })
          } else {
            this.jsonData = res.data
            this.isJsonShow = true
          }
        })
    }
  }
}
</script>
